import React from 'react';
import snackbar from 'utils/snackbar';

import { ListingScansForm } from './styles';

const ListingScanForm = ({ form }) => {
  const onSubmit = (data) => {
    const redirectUrl = `https://www.optimizelocation.com/partner/servicetitan/listing-report.html?name=${data.Company}&address=${data.Address}&city=${data.City}&state=${data.State}&zip=${data.Postal_Code}&phone=${data.Phone}`;
    data.resultsLink = redirectUrl;

    return data;
  };

  const onSuccess = (setValues, values) => {
    setValues({
      Address: '',
      City: '',
      Company: '',
      Email: '',
      Phone: '',
      PostalCode: '',
      ...values,
    });
    snackbar('Check your inbox for your full report!', 'success');
  };

  return (
    <ListingScansForm
      backgroundColor="transparent"
      buttonLabel="Scan Now"
      containerPadding="0"
      inlineMarketingForm={form}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      allowMultiSubmissions={true}
      shouldRedirect={false}
      showPrivacy={false}
      title="Check how your business appears online"
    />
  );
};

export default ListingScanForm;
