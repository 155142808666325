import styled from 'styled-components';
import MarketoForm from 'components/Form/MarketoForm';

const ListingScansForm = styled(MarketoForm)`
  background: var(--grey-1);
  padding: 25px;
  border-radius: 4px;

  .form-item {
    width: calc(50% - 10px);

    &:nth-child(even) {
      margin-left: 20px;
    }

    &:last-child {
      width: 100%;
    }
  }

  .State {
    margin-bottom: 0px !important;
  }

  .signup-form--submit-button {
    background: var(--titan-blue-3);
  }
`;

export { ListingScansForm };
