import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import DescriptionBlock from 'components/Tools/DescriptionBlock';
import ListingScansForm from 'components/Form/ListingScanForm';
import { createToolsBreadcrumbs } from '../utils/createToolsBreadcrumbs';

import { FormContainer } from 'components/Tools/styles';

const ReputationPage = (props) => {
  const data = props.data.contentfulTool;
  const listingScanForm = props.data.contentfulInlineMarketingForm;
  const listingScanSocialIcons = props.data.contentfulImage;
  const breadcrumbs = createToolsBreadcrumbs('Listing Scan');

  return (
    <ToolboxLayout breadcrumbs={breadcrumbs} contentfulSeo={data.seo} layout="toolbox" {...props}>
      <Hero data={data.hero} subheadingImage={listingScanSocialIcons} />
      <FormContainer>
        <div className="section-tool-page-form--wrapper">
          <div className="section-tool-page-form--container">
            <ListingScansForm icons={listingScanSocialIcons} form={listingScanForm} />
          </div>
        </div>
      </FormContainer>
      <DescriptionBlock data={data.description} />
    </ToolboxLayout>
  );
};

export default ReputationPage;

export const ReputationPageQuery = graphql`
  query ReputationPageQuery {
    contentfulTool(contentful_id: { eq: "501r8Zx3HdFJFhXNKfJ7qM" }) {
      ...Tool
    }
    contentfulImage(contentful_id: { eq: "3qKrZMNz2Zag7IxqkLSeRX" }) {
      ...WebsiteImage
    }
    contentfulInlineMarketingForm(contentful_id: { eq: "2UEzBngoSQS0f8OkxC9SI1" }) {
      ...InlineMarketingForm
    }
  }
`;
